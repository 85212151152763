<template>
  <no-type v-if="!form.data_import_type"
           @selectType="selectType" />
  <update-profile :formData="formData"
                  v-else
                  :errors="errors" />
</template>

<script>
import NoType from './NoType.vue';
import UpdateProfile from './UpdateProfile.vue';
export default {
  props: ['formData', 'errors'],
  components: {NoType, UpdateProfile},
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  methods: {
    selectType(type) {
      this.form.data_import_type = type;
    }
  }
}
</script>