<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title mb-0">Selected Desired Consent Form</h4>
    </div>
    <div class="box-body p-3">
      <table class="table table-small table-bordered">
        <tbody>
        <tr>
          <td style="width: 5%" class="text-center">
            <div class="checkbox checkbox-success">
              <input id="checkbox2" type="checkbox">
              <label for="checkbox2"></label>
            </div>
          </td>
          <td>
            General Service Agreement
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>