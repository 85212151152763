<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title mb-0">เลือกประเภทการนำเข้าข้อมูล</h4>
    </div>
    <div class="box-body p-3">
      <div class="row">
        <div class="col-6">
          <div class="rounded w-p100 bg-primary-light text-center p-30"
               @click="selectMethod(1)">
            <i class="fas fa-id-card fs-100"></i>
            <h4 class="text-dark mt-30">เสียบบัตรประชาชน</h4>
          </div>
        </div>
        <div class="col-6">
          <div class="rounded w-p100 bg-primary-light text-center p-30"
               @click="selectMethod(2)">
            <i class="fas fa-edit fs-100"></i>
            <h4 class="text-dark mt-30">กรอกข้อมูลเอง</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    selectMethod(type) {
      this.$emit('selectType', type)
    }
  }
}
</script>