<template>
  <div class="row">
    <div class="col-12 col-xl-4">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">{{ $t('dashboard.doctor_today') }}</h4>
        </div>
        <div class="box-body p-3">
          <div class="inner-user-div3">
            <div class="alert alert-secondary"
                 v-if="!todayDoctors.length"
                 role="alert">
              {{ $t('dashboard.no_items') }}
            </div>
            <div class="d-flex align-items-center mb-10"
                 v-for="doctor in todayDoctors"
                 :key="'doctor_'+doctor.id">
              <div class="me-15">
                <img :src="doctor.image ? doctor.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10 bg-primary-light" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#"
                   @click.prevent="$refs.doctorDetailsModal.show(doctor.id)"
                   class="text-dark hover-primary mb-1 fs-16">{{ doctor.full_name }}</a>
                <span class="text-fade">{{ doctor.department.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">{{ $t('dashboard.staff_today') }}</h4>
        </div>
        <div class="box-body p-3">
          <div class="inner-user-div3">
            <div class="alert alert-secondary"
                 v-if="!todayStaff.length"
                 role="alert">
              {{ $t('dashboard.no_items') }}
            </div>

            <div class="d-flex align-items-center mb-10"
                 v-for="staff in todayStaff"
                 :key="'staff_'+staff.id">
              <div class="me-15">
                <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10 bg-primary-light" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#"
                   class="text-dark hover-primary mb-1 fs-16">{{ staff.user.full_name }}</a>
                <span class="text-fade">{{ staff.staff_id }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">{{ $t('dashboard.days_14__visitation_stat') }}</h4>
        </div>
        <div class="box-body p-3">
          <div id="visitor_chart"></div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-4">
      <div class="box b-0 bg-transparent">
        <div class="box-body pt-5 pb-0 px-0">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Requests</h4>
            <router-link :to="{name: 'case_requests'}">All Requests <i class="ms-10 fa fa-angle-right"></i></router-link>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body p-15">
          <div class="alert alert-secondary"
               v-if="!requests.length"
               role="alert">
            {{ $t('dashboard.no_items') }}
          </div>
          <div class="mb-10 d-flex justify-content-between align-items-center"
               :key="'patient_'+req.id"
               v-for="req in requests">
            <div class="fw-600 min-w-120">
              {{ req.doctor_slot_date }}
            </div>
            <div class="w-p100 p-10 rounded10 justify-content-between align-items-center d-flex bg-lightest">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-0">{{ req.request_id }}</h6>
                </div>
              </div>
              <div class="dropdown">
                <a data-bs-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item"
                               :to="{name: 'case_requests_details', params: {id: req.id}}">
                    <i class="ti-eye"></i> Details
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box b-0 bg-transparent">
        <div class="box-body pt-5 pb-0 px-0">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Tasks</h4>
            <router-link :to="{name: 'tasks'}">All Tasks <i class="ms-10 fa fa-angle-right"></i></router-link>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body p-15">
          <div class="alert alert-secondary"
               v-if="!todayTasks.length"
               role="alert">
            {{ $t('dashboard.no_items') }}
          </div>
          <div class="mb-10 d-flex justify-content-between align-items-center"
               :key="'task_'+task.id"
               v-for="task in todayTasks">
            <div class="fw-600 min-w-120">
              {{ task.time }}
            </div>
            <div class="w-p100 p-10 rounded10 justify-content-between align-items-center d-flex bg-lightest">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-0">{{ task.title }}</h6>
                  <p class="mb-0 fs-12 text-mute">{{ task.task_id }}</p>
                </div>
              </div>
              <div class="dropdown">
                <a data-bs-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item"
                               :to="{name: 'tasks_view', params: {id: task.id}}">
                    <i class="ti-eye"></i> Details
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-4">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">Cases</h4>
        </div>
        <div class="box-body p-3">
          <div id="upcoming_appointment_paginator"></div>
        </div>
        <div class="box-body">
          <div class="slim-scroll">
            <div class="alert alert-secondary"
                 v-if="!upcomingAppointments.length"
                 role="alert">
              {{ $t('dashboard.no_items') }}
            </div>
            <div v-for="appointment in upcomingAppointments"
                 :key="'appointment_'+appointment.id">
              <div class="d-flex align-items-center mb-10">
                <div class="me-15">
                  <img :src="(appointment.patient && appointment.patient.image) ? appointment.patient.image : '/images/avatar-girl.png'"
                       onerror="this.src='/images/avatar-girl.png'"
                       class="avatar avatar-lg rounded10 bg-primary-light" alt="" />
                </div>
                <div class="d-flex flex-column flex-grow-1 fw-500">
                  <p class="hover-primary text-fade mb-1 fs-14">{{ appointment.patient ? appointment.patient.full_name : '' }}</p>
                  <span class="text-dark fs-16">{{ appointment.services.map(s => s.name_th).join(', ') }}</span>
                </div>
                <div>
                  <router-link :to="{name: 'case_notes', params: {id: appointment.id}}"
                               class="waves-effect waves-circle btn btn-circle btn-primary-light btn-sm">
                    <i class="fa fa-eye"></i>
                  </router-link>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-end mb-15 py-10 bb-dashed border-bottom">
                <div>
                  <p class="mb-0 text-muted"><i class="fa fa-clock-o me-5"></i> {{ appointment.start_time_format }} <span class="mx-20">฿ 3,000</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">{{ $t('dashboard.service_by_category_this_month') }}</h4>
        </div>
        <div class="box-body p-3">
          <div id="service_by_month_chart"></div>
        </div>
      </div>-->
    </div>
  </div>

  <doctor-details-modal ref="doctorDetailsModal" />
</template>

<script>
import DoctorDetailsModal from "@/views/doctor/modals/DoctorDetailsModal.vue";

export default {
  components: {DoctorDetailsModal},
  data() {
    return {
      todayDoctors: [],
      todayTasks: [],
      requests: [],
      todayStaff: [],
      todayPatients: [],
      upcomingAppointments: [],
      last14daysVisits: null,
      serviceCategoryByMonth: null
    }
  },
  created() {
    this.loadTodayDoctor();
    this.loadTodayStaff();
    this.loadTodayPatient();
    this.loadRequests();
    this.loadUpcomingAppointment();
    this.loadTodayTasks();
  },
  mounted() {
    this.loadLast14DaysVisits();
    // this.loadThisMonthService();
    this.initUpcomingPaginator();
    this.initSlimScroll();
  },
  methods: {
    loadTodayTasks() {
      this.todayTasks = [];
      this.axios.get('/dashboard/today-tasks')
        .then(res => {
          this.todayTasks = res.data.data;
        })
    },
    loadRequests() {
      this.requests = [];
      this.axios.get('/dashboard/requests')
        .then(res => {
          this.requests = res.data.data;
        })
    },
    loadThisMonthService() {
      this.axios.get('/dashboard/service-category-this-month')
        .then(res => {
          this.serviceCategoryByMonth = res.data.data;

          setTimeout(() => {
            this.initServiceByMonthChart();
          }, 100)
        })
    },
    loadLast14DaysVisits() {
      this.axios.get('/dashboard/last-14-days-visits')
        .then(res => {
          this.last14daysVisits = res.data.data;

          setTimeout(() => {
            this.initVisitorChart();
          }, 100)
        })
    },
    loadTodayDoctor() {
      this.todayDoctors = [];

      this.axios.get('/dashboard/today-dashboard')
        .then(res => {
          this.todayDoctors = res.data.data;
        })
    },
    loadTodayStaff() {
      this.todayStaff = [];

      this.axios.get('/dashboard/today-staff')
        .then(res => {
          this.todayStaff = res.data.data;
        })
    },
    loadTodayPatient() {
      this.todayPatients = [];

      this.axios.get('/dashboard/appointments')
        .then(res => {
          this.todayPatients = res.data.data;
        });
    },
    loadUpcomingAppointment(date = '') {
      this.upcomingAppointments = [];
      this.axios.get('/dashboard/appointments?date=' + date)
        .then(res => {
          this.upcomingAppointments = res.data.data;
        })
    },
    initSlimScroll() {
      window.$('.slim-scroll').slimScroll({
        height: '315px'
      });
    },
    initUpcomingPaginator() {
      let self = this;

      window.$("#upcoming_appointment_paginator").datepaginator({
        onSelectedDateChanged: function(event, date) {
          self.loadUpcomingAppointment(date.format('YYYY-MM-DD'));
        }
      })
    },
    initServiceByMonthChart() {
      window.$("#service_by_month_chart").html("");
      let self = this;

      let options = {
        series: [{
          name: 'Total',
          data: self.serviceCategoryByMonth.count
        }],
        chart: {
          type: 'bar',
          height: 205,
          toolbar: {
            show: false
          }
        },
        colors:['#5156be'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: false,
        },
        stroke: {
          show: false,
          width: 0,
          colors: ['transparent']
        },
        xaxis: {
          categories: self.serviceCategoryByMonth.names,
        },

        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }

        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      };

      let chart = new window.ApexCharts(document.querySelector("#service_by_month_chart"), options);
      chart.render();
    },
    initVisitorChart() {
      window.$("#visitor_chart").html("");
      let self = this;

      let options = {
        series: [
          {
            name: "Visitors",
            data: self.last14daysVisits.count
          }
        ],
        chart: {
          height: 205,
          type: 'area',
          toolbar: {
            show: false
          }
        },
        colors: ['#05825f'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
        },
        xaxis: {
          categories: self.last14daysVisits.dates,
        },
        legend: {
          show: false,
        }
      };

      let chart = new window.ApexCharts(document.querySelector("#visitor_chart"), options);
      chart.render();
    }
  }
}
</script>