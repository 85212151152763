<template>
  <div class="row">
    <div class="col-12 col-xl-4">
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">Your Services Today</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-6">
              <div style="min-height: 156px;">
                <div id="chart124"></div>
              </div>
            </div>
            <div class="col-6 d-flex align-items-center">
              <table class="table table-borderless table-small" v-if="serviceToday">
                <tr>
                  <td>
                    <span class="badge badge-xl badge-dot badge-primary me-15"></span>
                    Completed
                  </td>
                  <td>{{ serviceToday.completed }}</td>
                </tr>
                <tr>
                  <td>
                    <span class="badge badge-xl badge-dot badge-primary-light me-15"></span>
                    Waiting
                  </td>
                  <td>{{ serviceToday.waiting }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header with-border small-box-header">
          <h4 class="box-title">Upcoming Appointments</h4>
        </div>
        <div class="box-body p-3">
          <div id="upcoming_appointment_paginator"></div>
        </div>
        <div class="box-body">
          <div class="slim-scroll">
            <div class="alert alert-secondary"
                 v-if="!upcomingAppointments.length"
                 role="alert">
              {{ $t('dashboard.no_items') }}
            </div>
            <div v-for="appointment in upcomingAppointments"
                 :key="'appointment_'+appointment.id">
              <div class="d-flex align-items-center mb-10">
                <div class="me-15">
                  <img :src="appointment.patient.image ? appointment.patient.image : '/images/avatar-girl.png'"
                       onerror="this.src='/images/avatar-girl.png'"
                       class="avatar avatar-lg rounded10 bg-primary-light" alt="" />
                </div>
                <div class="d-flex flex-column flex-grow-1 fw-500">
                  <p class="hover-primary text-fade mb-1 fs-14">{{ appointment.patient.full_name }}</p>
                  <span class="text-dark fs-16">{{ appointment.services.map(s => s.name_th).join(', ') }}</span>
                </div>
                <div>
                  <router-link :to="{name: 'case_notes', params: {id: appointment.id}}"
                               class="waves-effect waves-circle btn btn-circle btn-primary-light btn-sm">
                    <i class="fa fa-eye"></i>
                  </router-link>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-end mb-15 py-10 bb-dashed border-bottom">
                <div>
                  <p class="mb-0 text-muted"><i class="fa fa-clock-o me-5"></i> {{ appointment.start_time_format }} <span class="mx-20">฿ 3,000</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-4">
      <div class="box b-0 bg-transparent">
        <div class="box-body pt-5 pb-0 px-0">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0">Your Patients Today</h4>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body p-15">
          <div class="alert alert-secondary"
               v-if="!todayPatients.length"
               role="alert">
            {{ $t('dashboard.no_items') }}
          </div>
          <div class="mb-10 d-flex justify-content-between align-items-center"
               :key="'patient_'+patient.id"
               v-for="patient in todayPatients">
            <div class="fw-600 min-w-120">
              {{ patient.start_time_format }}
            </div>
            <div class="w-p100 p-10 rounded10 justify-content-between align-items-center d-flex bg-lightest">
              <div class="d-flex justify-content-between align-items-center">
                <img :src="patient.patient.image ? patient.patient.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="me-10 avatar rounded-circle" alt="">
                <div>
                  <h6 class="mb-0">{{ patient.patient.full_name }}</h6>
                  <p class="mb-0 fs-12 text-mute">{{ patient.services.map(s => s.name_th).join(', ') }}</p>
                </div>
              </div>
              <div class="dropdown">
                <a data-bs-toggle="dropdown" href="#"><i class="ti-more-alt rotate-90"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item"
                               :to="{name: 'case_notes', params: {id: patient.id}}">
                    <i class="ti-eye"></i> Details
                  </router-link>
                  <router-link class="dropdown-item"
                               :to="{name: 'check_in', params: {id: patient.id}}">
                    <i class="ti-location-pin"></i> Check-in
                  </router-link>
                  <router-link class="dropdown-item"
                               :to="{name: 'patients_details', params: {id: patient.patient.id}}">
                    <i class="ti-user"></i> Profile
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-4">
      <div class="box">
        <div class="box-body p-3 h-500">
          <div id="slot-calendar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceToday: null,
      upcomingAppointments: [],
      todayPatients: [],
      events: [],
    }
  },
  created() {
    this.loadUpcomingAppointment();
  },
  mounted() {
    this.loadServiceToday();
    this.initCalender();
    this.loadTodayPatient();
  },
  methods: {
    loadTodayPatient() {
      this.todayPatients = [];

      this.axios.get('/doctor-dashboard/appointments')
        .then(res => {
          this.todayPatients = res.data.data;

          this.events = res.data.data.map(e => {
            let event = {
              start: e.start_time,
              end: e.end_time,
            }

            event.title = e.case_no + ' - ' + e.patient.full_name;
            event.className = 'bg-info';

            return event;
          })

          setTimeout(() => {
            this.initCalender();
          }, 500)
        });
    },
    loadServiceToday() {
      this.axios.get('/doctor-dashboard/today-services')
        .then(res => {
          this.serviceToday = res.data.data;

          setTimeout(() => {
            this.initServiceTodayChart();
          }, 100)
        })
    },
    loadUpcomingAppointment(date = '') {
      this.upcomingAppointments = [];
      this.axios.get('/doctor-dashboard/appointments?date=' + date)
        .then(res => {
          this.upcomingAppointments = res.data.data;
        })
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('slot-calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'timeGridDay',
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: ''
        },
        themeSystem: 'bootstrap5',
        events: self.events,
      });
      calendar.render();
    },
    initServiceTodayChart() {
      window.$("#chart124").html("");

      let options = {
        series: [this.serviceToday.completed, this.serviceToday.waiting],
        chart: {
          type: 'donut',
          width: 186,
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#5156be', '#c8c9ee'],
        legend: {
          show: false,
        },

        plotOptions: {
          pie: {
            donut: {
              size: '75%',
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true
                }
              },
            }
          }
        },
        labels: ["Completed", "Waiting"],
        responsive: [{
          breakpoint: 1600,
          options: {
            chart: {
              width: 175,
            }
          }
        },{
          breakpoint: 500,
          options: {
            chart: {
              width: 200,
            }
          }
        }]
      };

      let chart = new window.ApexCharts(document.querySelector("#chart124"), options);
      chart.render();
    }
  }
}
</script>