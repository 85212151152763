<template>
  <div class="box">
    <div class="box-header small-box-header">
      <h4 class="box-title mb-0">Update Profile</h4>
    </div>
    <div class="box-body p-3">
      <div class="row">
        <div class="col-6">
          <h6 class="text-primary">Profile</h6>

          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('name_prefix_id')}]">
            <label class="col-sm-4 col-form-label">คำนำหน้า <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.patient.name_prefix_id">
                <option value="">Select Option</option>
                <option :value="prefix.id"
                        v-for="prefix in prefixes"
                        :key="'prefix_'+prefix.id">{{ prefix.prefix }}</option>
              </select>

              <v-errors :errors="errorFor('name_prefix_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('first_name')}]">
            <label class="col-sm-4 col-form-label">ชื่อลูกค้า <span class="text-danger">*</span></label>
            <div class="col-sm-4">
              <input class="form-control form-control-sm"
                     v-model="form.patient.first_name"
                     type="text">

              <v-errors :errors="errorFor('first_name')"></v-errors>
            </div>
            <div class="col-sm-4">
              <input class="form-control form-control-sm"
                     v-model="form.patient.last_name"
                     type="text">

              <v-errors :errors="errorFor('last_name')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('nick_name')}]">
            <label class="col-sm-4 col-form-label">ชื่อเล่น</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.nick_name"
                     type="text">

              <v-errors :errors="errorFor('nick_name')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('gender')}]">
            <label class="col-sm-4 col-form-label">เพศ</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.patient.gender">
                <option value="">Select Option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>

              <v-errors :errors="errorFor('gender')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('dob')}]">
            <label class="col-sm-4 col-form-label">วันเกิด <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.dob"
                     type="date">

              <v-errors :errors="errorFor('dob')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('occupation')}]">
            <label class="col-sm-4 col-form-label">อาชีพ</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.occupation"
                     type="text">

              <v-errors :errors="errorFor('occupation')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('occupation_other')}]">
            <label class="col-sm-4 col-form-label">อาชีพ (อื่น)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.occupation_other"
                     type="text">

              <v-errors :errors="errorFor('occupation_other')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('nationality')}]">
            <label class="col-sm-4 col-form-label">สัญชาติ</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.nationality"
                     type="text">

              <v-errors :errors="errorFor('nationality')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('id_card_number')}]">
            <label class="col-sm-4 col-form-label">หมายเลขบัตรประจำตัวประชาชน</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.id_card_number"
                     type="text">

              <v-errors :errors="errorFor('id_card_number')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('passport_number')}]">
            <label class="col-sm-4 col-form-label">หมายเลขหนังสือเดินทาง</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.passport_number"
                     type="text">

              <v-errors :errors="errorFor('passport_number')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('blood_group_id')}]">
            <label class="col-sm-4 col-form-label">หมู่เลือด <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.patient.blood_group_id">
                <option value="">Select Option</option>
                <option :value="group.id"
                        v-for="group in bloodGroups"
                        :key="'blood_group_'+group.id">{{ group.name }}</option>
              </select>

              <v-errors :errors="errorFor('blood_group_id')"></v-errors>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h6 class="text-primary">Contact</h6>

          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.country_id')}]">
            <label class="col-sm-4 col-form-label">ประเทศ</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.patient.address.country_id">
                <option value="">Choose</option>
                <option :value="country.id"
                        v-for="country in countries"
                        :key="'country_'+country.id">{{ country.name }}</option>
              </select>

              <v-errors :errors="errorFor('address.country_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('mobile')}]">
            <label class="col-sm-4 col-form-label">เบอร์มือถือ <span class="text-danger">*</span></label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.mobile"
                     type="text">

              <v-errors :errors="errorFor('mobile')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('mobile_alternative')}]">
            <label class="col-sm-4 col-form-label">เบอร์มือถือ (สำรอง)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.mobile_alternative"
                     type="text">

              <v-errors :errors="errorFor('mobile_alternative')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('email')}]">
            <label class="col-sm-4 col-form-label">อีเมล</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.email"
                     type="text">

              <v-errors :errors="errorFor('email')"></v-errors>
            </div>
          </div>

          <h6 class="text-primary mt-3">Address</h6>

          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.house_no')}]">
            <label class="col-sm-4 col-form-label">บ้านเลขที่</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.house_no"
                     type="text">

              <v-errors :errors="errorFor('address.house_no')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.village')}]">
            <label class="col-sm-4 col-form-label">หมู่บ้าน/อพาร์ทเมนท์/คอนโด</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.village"
                     type="text">

              <v-errors :errors="errorFor('address.village')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.group')}]">
            <label class="col-sm-4 col-form-label">หมู่</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.group"
                     type="text">

              <v-errors :errors="errorFor('address.group')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.alley')}]">
            <label class="col-sm-4 col-form-label">ซอย</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.alley"
                     type="text">

              <v-errors :errors="errorFor('address.alley')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.road')}]">
            <label class="col-sm-4 col-form-label">ถนน</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.road"
                     type="text">

              <v-errors :errors="errorFor('address.road')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.district')}]">
            <label class="col-sm-4 col-form-label">เขต/อำเภอ</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.district"
                     type="text">

              <v-errors :errors="errorFor('address.district')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.sub_district')}]">
            <label class="col-sm-4 col-form-label">แขวง/ตำบล</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.sub_district"
                     type="text">

              <v-errors :errors="errorFor('address.sub_district')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.province')}]">
            <label class="col-sm-4 col-form-label">จังหวัด</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.province"
                     type="text">

              <v-errors :errors="errorFor('address.province')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.zip_code')}]">
            <label class="col-sm-4 col-form-label">รหัสไปรษณีย์</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.zip_code"
                     type="text">

              <v-errors :errors="errorFor('address.zip_code')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.country_id')}]">
            <label class="col-sm-4 col-form-label">ประเทศ</label>
            <div class="col-sm-8">
              <select class="form-select form-select-sm"
                      v-model="form.patient.address.country_id">
                <option value="">Choose</option>
                <option :value="country.id"
                        v-for="country in countries"
                        :key="'country_'+country.id">{{ country.name }}</option>
              </select>

              <v-errors :errors="errorFor('address.country_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('address.phone')}]">
            <label class="col-sm-4 col-form-label">โทรศัพท์ (บ้าน)</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm"
                     v-model="form.patient.address.phone"
                     type="text">

              <v-errors :errors="errorFor('address.phone')"></v-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      prefixes: [],
      countries: [],
      bloodGroups: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  created() {
    this.loadPrefixes();
    this.loadCountries();
    this.loadBloodGroups();
  },
  methods: {
    loadPrefixes() {
      this.prefixes = [];

      this.axios.get('/name-prefixes')
        .then(res => {
          this.prefixes = res.data.data;
        })
    },
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        });
    },
    loadBloodGroups() {
      this.bloodGroups = [];

      this.axios.get('/blood-groups')
        .then(res => {
          this.bloodGroups = res.data.data;
        });
    },
  }
}
</script>