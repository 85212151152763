<template>
  <h4>Check-in</h4>

  <div class="row">
    <div class="col-2">
      <div class="d-grid">
        <button :class="'btn btn-sm me-2  mb-2 ' + (currentStep === 1 ? 'btn-primary' : 'btn-secondary')">1. Update Profile</button>
        <button :class="'btn btn-sm me-2  mb-2 ' + (currentStep === 2 ? 'btn-primary' : 'btn-secondary')">2. Emergency Contact</button>
        <button :class="'btn btn-sm me-2  mb-2 ' + (currentStep === 3 ? 'btn-primary' : 'btn-secondary')">3. Desired Consent Form</button>
      </div>
    </div>
    <div class="col-10">
      <component v-bind:is="currentComponent"
                 :formData="form"
                 :errors="allErrors" />
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-6">
      <router-link class="btn btn-secondary btn-sm"
                   :to="{name: 'dashboard_clinic'}">ยกเลิก</router-link>
    </div>
    <div class="col-md-6 text-end">
      <button type="button"
              @click="prevStep"
              :disabled="currentStep === 1"
              :class="['btn me-3 btn-sm', {'btn-info-light': currentStep === 1}, {'btn-primary-light': currentStep !== 1}]">
        <i class="fas fa-arrow-left"></i> ย้อนกลับ
      </button>
      <button type="button"
              @click="nextStep"
              v-if="currentStep !== 3"
              :class="['btn btn-sm', {'btn-info-light': currentStep === 4}, {'btn-primary': currentStep !== 4}]">
        ถัดไป <i class="fas fa-arrow-right"></i>
      </button>
      <button type="button"
              v-else
              @click="submit"
              :disabled="loading"
              class="btn btn-primary btn-sm">
        เสร็จสิ้น
      </button>
    </div>
  </div>
</template>

<script>
import StepOne from './steps/1/StepOne.vue';
import StepTwo from './steps/2/StepTwo.vue';
import StepThree from './steps/3/StepThree.vue';
export default {
  components: {StepOne, StepTwo, StepThree},
  data() {
    return {
      visitCase: null,
      currentStep: 1,
      allErrors: null,
      form: {
        data_import_type: '',
        patient: {
          image: '',
          name_prefix_id: '',
          nick_name: '',
          first_name: '',
          last_name: '',
          gender: '',
          country_id: '',
          dob: '',
          nationality: '',
          id_card_number: '',
          passport_number: '',
          occupation: '',
          occupation_other: '',
          mobile: '',
          mobile_alternative: '',
          email: '',
          blood_group_id: '',
          congenital_disease: '',
          drug_allergy: '',
          note: '',
          status: '1',
          address: {
            country_id: '',
            house_no: '',
            village: '',
            group: '',
            alley: '',
            road: '',
            province: '',
            district: '',
            sub_district: '',
            zip_code: '',
            phone: '',
          },
          emergencyContacts: [],
        }
      }
    }
  },
  computed: {
    currentComponent() {
      if (this.currentStep === 1)
        return 'step-one';
      else if  (this.currentStep === 2)
        return 'step-two';
      else if  (this.currentStep === 3)
        return 'step-three';
      else if  (this.currentStep === 4)
        return 'step-four';
      else
        return 'step-five';
    },
  },
  created() {
    this.loadVisitCase();
  },
  methods: {
    nextStep() {
      this.currentStep++;
    },
    prevStep() {
      this.currentStep--;
    },
    loadVisitCase() {
      this.axios.get('/visit-cases/' + this.$route.params.id)
        .then(res => {
          this.visitCase = res.data.data;

          this.loadPatient(this.visitCase.patient_id);
        })
    },
    loadPatient(patientId) {
      this.axios.get('/patients/' + patientId)
        .then(res => {
          let patient = res.data.data;
          this.form.patient = {...this.form.patient, ...patient};

          if (patient.addresses.length) {
            this.form.patient.address = {...patient.addresses[0]};
            delete this.form.patient.address.id;
          }

          delete this.form.patient.id;
          delete this.form.patient.addresses;
        })
    }
  }
}
</script>